import 'toastr'
import jQuery from 'jquery'

window.$ = jQuery;
window.jQuery = jQuery;

import 'jquery-colorbox'
import 'bootstrap'

window.delayID = -1;
window.delayLengde = 100;
var regDatoNorge = /^\d{2}(\.)\d{2}(\.)\d{4}$/;
var regDatoSverige = /^\d{4}(\.)\d{2}(\.)\d{2}$/;

window.sensitiveArtsdata = window.sensitiveArtsdata || {};

function huskEkspandertSokepanel(chkEkspandert) {
    if (chkEkspandert.is(":checked")) {
        jQuery('#pnlContentEkstrafelter').collapse('show');
    }

    jQuery('#pnlContentEkstrafelter').on('shown.bs.collapse', function () {
        chkEkspandert.prop('checked', true);
    });

    jQuery('#pnlContentEkstrafelter').on('hidden.bs.collapse', function () {
        chkEkspandert.prop('checked', false);
    });
}

window.settDagensdato = function(elem, strSpraakId) {
    var varDato = new Date();
    var strDag;
    var strMaaned;
    var strAar = varDato.getFullYear();
    var intDag = varDato.getDate();
    var intMaaned = varDato.getMonth() + 1;

    if (intDag < 10) {
        strDag = "0" + intDag;
    }
    else {
        strDag = intDag;
    }

    if (intMaaned < 10) {
        strMaaned = "0" + intMaaned;
    }
    else {
        strMaaned = intMaaned;
    }

    if (strSpraakId === 2) {
        elem.value = strAar + "." + strMaaned + "." + strDag;
    }
    else {
        elem.value = strDag + "." + strMaaned + "." + strAar;
    }
}

window.sjekkDatoformat = function(objElement, språkID, strMelding) {
    let varRetur;

    if (objElement.value !== "" && objElement.value !== "dd.mm.åååå" && objElement.value !== "dd.mm.yyyy" && objElement.value !== "ssåå.mm.dd") {
        if (språkID === 2) {
            varRetur = regDatoSverige.exec(objElement.value);

        }
        else {
            varRetur = regDatoNorge.exec(objElement.value);
        }

        if (!varRetur) {
            objElement.value = "";

            objElement.focus();

            alert(strMelding);
        }
    }
}

window.sjekkDatoformatOgGyldighet = function(objElement, språkId, strMeldingFeilFormat, strMeldingUtenforBeskrankning) {
    let varRetur;

    if (objElement.value !== "" && objElement.value !== "dd.mm.åååå" && objElement.value !== "dd.mm.yyyy" && objElement.value !== "ssåå.mm.dd") {
        if (språkId === 2) {
            varRetur = regDatoSverige.exec(objElement.value);
        }
        else {
            varRetur = regDatoNorge.exec(objElement.value);
        }

        if (!varRetur) {
            objElement.value = "";
            objElement.focus();

            alert(strMeldingFeilFormat);
        }
        else {
            // hvis dato har gyldig format må det sjekkes at dato ikke er større enn dagens dato og ikke eldre enn 01.01.1900
            let datoStreng = objElement.value;
            let datesplit = datoStreng.split(".");
            let year;
            let month = Number(datesplit[1]) - 1;
            let day;

            if (språkId === 2) {
                // sverige
                year = Number(datesplit[0]);
                day = Number(datesplit[2]);
            }
            else {
                year = Number(datesplit[2]);
                day = Number(datesplit[0]);
            }

            var dateobj = new Date(year, month, day, 0, 0, 0);
            var now = new Date();
            var then = new Date(1900, 0, 1, 0, 0, 0);

            if (dateobj > now || dateobj < then) {
                objElement.value = "";
                objElement.focus();

                alert(strMeldingUtenforBeskrankning);
            }
        }
    }
}

function CellClick(index, passord, clientId)
{
    const grid = $find(clientId);
    const masterTable = grid.get_masterTableView();
    const rows = masterTable.get_dataItems();
    for (var i = 0; i < rows.length; i++)
    {
        let row = rows[i];
        for (var j = 0; j < row._element.cells.length; j++)
        {
            let cell = row._element.cells[j];
            if(row._itemIndexHierarchical == index)
            {
                let celltext = cell.innerHTML;
                alert(passord);
                cell.style.backgroundColor = "Red";
            }
            else
            {
                cell.style.backgroundColor = "";
            }
        }
    }
}
function fallbackCopyTextToClipboard(text, successText, failureText) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var copied = document.execCommand('copy');
        if (copied) {
            toastr.success(successText);
        } else {
            toastr.error(failureText);
        }
    } catch (err) {
        toastr.error(failureText);
    }

    document.body.removeChild(textArea);
}

window.copyTextToClipboard = function(text, successText, failureText) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text, successText, failureText);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        toastr.success(successText);
    }, function(err) {
        toastr.error(failureText, err);
    });
}

window.aktiverPopoverForHjelpetekster = function () {
    jQuery('.popover').popover('destroy');
    jQuery('.rb-tooltip').on('click', function () {
        jQuery('.rb-tooltip').not(this).popover('hide');
    });
    jQuery('.rb-tooltip').popover({ 'html': true, 'placement': 'bottom', 'trigger': 'click', 'viewport': 'body', 'container': 'body' });
    jQuery('.collapse').on('hidden.bs.collapse', function () {
        jQuery('.rb-tooltip').popover('hide');
    });
};

window.aktiverSokeresultatAffix = function () {
    jQuery(".DivSokeresultatVerktoy").rbAffix({
        rbAffixActivationBreakpoint: 768,
        rbAffixOffsetElement: ".DivSokeresultatVerktoyContainer",
        rbAffixGrid: ".DivSokeresultat",
        rbAffixContainer: ".DivSokeresultatVerktoyContainer"
    });
};

window.aktiverResponsivVisning = function() {
    jQuery(".rovbaseMapToolbar").addClass("rovbaseMapToolbar-responsive");
    jQuery(".rovbaseMapToolbar .olButton")
        .bind("touchstart click",
            function() {
                jQuery(".rovbaseMapToolbar").toggleClass("rovbaseMapToolbar-choosing");
            });
};

window.merkStatiskeMeldinger = function () {
    if ((jQuery("#systemmelding > div > div").length === 1) && (jQuery(".DivSokeresultat").length === 1)) {
        jQuery("#systemmelding").addClass("systemmelding-statisk");
    }
};

window.sensitiveArtsdata.systemmelding = {};
window.sensitiveArtsdata.systemmelding.toast = {};

toastr.options = {
    positionClass: 'sensitiveArtsdataToast'
}

window.sensitiveArtsdata.systemmelding.toast.visOk = function (tekst) {
    toastr.success(tekst);
},

    window.sensitiveArtsdata.session = window.sensitiveArtsdata.session || {};
window.sensitiveArtsdata.session.TimerReminder = {};
window.sensitiveArtsdata.session.TimerTimeout = {};
window.sensitiveArtsdata.session.settings = {
    MinutesToSessionTimeout: {},
    WarningMinutesBeforeTimeout: {},
    RedirectUrl: {}
};

window.sensitiveArtsdata.session.InitTimers = function (intMinutesToSessionTimeout, intWarningMinutesBeforeTimeout, strRedirectUrl) {
    var settings = {
        MinutesToSessionTimeout: intMinutesToSessionTimeout,
        WarningMinutesBeforeTimeout: intWarningMinutesBeforeTimeout,
        RedirectUrl: strRedirectUrl
    };

    window.sensitiveArtsdata.session.settings = settings;

    window.sensitiveArtsdata.session.SetTimers();
};

window.sensitiveArtsdata.session.SetTimers = function () {
    clearTimeout(window.sensitiveArtsdata.session.TimerReminder);
    clearTimeout(window.sensitiveArtsdata.session.TimerTimeout);

    var settings = window.sensitiveArtsdata.session.settings,
        safetyBuffer = 0,
        minutesToRedirect = settings.MinutesToSessionTimeout - safetyBuffer,
        intMillisecondsToWarning = (minutesToRedirect - settings.WarningMinutesBeforeTimeout) * 60000,
        intMillisecondsToRedirect = minutesToRedirect * 60000;

    if (intMillisecondsToWarning < 0) {
        intMillisecondsToWarning = 1000;
    }

    window.sensitiveArtsdata.session.TimerReminder = setTimeout('window.sensitiveArtsdata.session.ShowTimeoutWarning()', intMillisecondsToWarning);
    window.sensitiveArtsdata.session.TimerTimeout = setTimeout(function () {
        window.sensitiveArtsdata.session.RedirectToUrl(settings.RedirectUrl);
    }, intMillisecondsToRedirect);
};

window.sensitiveArtsdata.session.ShowTimeoutWarning = function () {
    //ny master
    var timeoutmelding = jQuery(".modal-timeoutmelding");
    if (timeoutmelding.length === 1) {
        jQuery('.modal').not('.modal-timeoutmelding').modal('hide');
        timeoutmelding.modal('show');
        return;
    }

    // gammel master
    var divTimeoutmelding = jQuery("#divTimeoutmelding");
    if (divTimeoutmelding.length === 1) {
        divTimeoutmelding.show();
        return;
    }
};

window.sensitiveArtsdata.session.DismissTimeoutWarningModal = function () {
    jQuery(".modal-timeoutmelding").modal('hide');
    window.sensitiveArtsdata.session.RenewTimeout();
}

window.sensitiveArtsdata.session.DismissTimeoutWarning = function () {
    jQuery("#divTimeoutmelding").hide();
    window.sensitiveArtsdata.session.RenewTimeout();
}

window.sensitiveArtsdata.session.RenewTimeout = function () {
    $.get("/Home/RefreshSession", window.sensitiveArtsdata.session.SetTimers);
};

window.sensitiveArtsdata.session.fjernSystemmelding = function () {
    jQuery.ajax({
        type: "GET",
        url: "/Home/FjernSystemmelding",
        dataType: "json",
        contentType: "application/json; charset=utf-8"
    });
};

window.sensitiveArtsdata.session.RedirectToUrl = function (url) {
    window.location.href = url;
};

window.sensitiveArtsdata.bootbox = window.sensitiveArtsdata.bootbox || {};

window.sensitiveArtsdata.bootbox.confirm = function (elem, e) {

    if (!e || !elem) return;

    e.preventDefault();

    var data = jQuery(elem).data();
    var i18n =
        {
            message: "Er du sikker?",
            title: "Bekreft",
            confirm: "Ja",
            cancel: "Nei"
        };

    if (data.i18n) {
        jQuery.extend(i18n, data.i18n);
    }

    var options = {
        message: i18n.message,
        title: i18n.title,
        buttons: {
            confirm: { label: i18n.confirm, className: "btn-mdir" },
            cancel: { label: i18n.cancel, className: "btn-mdir-secondary" }
        },
        callback: function (res) {
            if (res === true) {
                __doPostBack(data.uniqueId, "");
            }
        }
    };

    bootbox.confirm(options);
};


/* Bootstrap Affix-funksjonalitet*/

(function ($) {

    $.fn.rbAffix = function (options) {

        var settings = $.extend({
            rbAffixOffsetElement: "body",       // Offset top for dette elementet brukes for å bestemme når affix-funksjonaliteten skal starte
            rbAffixElement: this,               // Elementet som skal affix'es
            rbAffixActivationBreakpoint: 768,   // Breakpoint på når affixfunksjonaliteten skal slås på. Mindre enn dette vil ikke gi affix
            rbAffixContainer: null,             // Wrapper for affixen, brukes for å bevare flyten i siden
            rbAffixGrid: null                   // Settes hvis affixen skal scrolle ved siden av en grid, vil få klassen "affixgrid" når affix er aktiv
        }, options);

        if ($(settings.rbAffixOffsetElement).length && $(window).width() > settings.rbAffixActivationBreakpoint - 1) {

            // Setter høyden og bredden til containere fast for å unngå hopp når affix slår inn.
            $(settings.rbAffixContainer).css("width", $(settings.rbAffixContainer).outerWidth() + "px");
            $(settings.rbAffixContainer).css("height", "0");

            // Når affix er aktivert plasserer vi den ved siden av grid
            $(settings.rbAffixElement).on("affixed.bs.affix", function () {
                $(settings.rbAffixGrid).css("width", "auto");
                $(settings.rbAffixGrid).css("width", ($(settings.rbAffixGrid).outerWidth() - $(settings.rbAffixElement).outerWidth() - 15) + "px");
                $(settings.rbAffixElement).css("left", ($(settings.rbAffixGrid).outerWidth() + $(settings.rbAffixGrid).offset().left + 15) + "px");
            });
            // Når affix deaktiveres plasseres den på den vanlige plassen
            $(settings.rbAffixElement).on("affixed-top.bs.affix", function () {
                $(settings.rbAffixGrid).css("width", "auto");
                $(settings.rbAffixElement).css("left", "");
            });

            $(settings.rbAffixElement).affix({
                offset: {
                    top: function () {
                        return $(settings.rbAffixOffsetElement).offset().top;
                    }
                }
            });
        }

        $(window).resize(
            function () {
                if ($(".DivSokeresultatVerktoy").length === 0) {
                    return;
                }
                $(window).off(".affix");
                $(settings.rbAffixElement).removeClass("affix affix-top affix-bottom").removeData("bs.affix").removeData("bs.affixed").removeData("bs.affixed-top");
                $(settings.rbAffixGrid).css("width", "auto");
                $(settings.rbAffixContainer).css("width", "auto");
                $(settings.rbAffixContainer).css("height", "auto");

                if (!$(window).width() < settings.rbAffixActivationBreakpoint) {
                    if ($(settings.rbAffixOffsetElement).length) {
                        $(settings.rbAffixElement).affix({
                            offset: {
                                top: function() {
                                    return $(settings.rbAffixOffsetElement).offset().top;
                                }
                            }
                        });
                    }
                }
            }
        );
    };

}(jQuery));

window.visVennligstVent = function() {
    var divVennligstVent = jQuery("#divVennligstVent");

    // ny master
    if (divVennligstVent.length !== null && divVennligstVent.hasClass("modal")) {
        //if (!jQuery('body').hasClass('modal-open')) {
        divVennligstVent.modal('show');
        //}
        return;
    }
}

